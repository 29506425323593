/* Vendor imports */
import { graphql } from "gatsby";
import React from "react";
import PropTypes from "prop-types";

import { Disqus, CommentCount } from 'gatsby-plugin-disqus'

class Comments extends React.Component {
  disqusConfig;
  titlePage;

  componentDidMount() {
    
    const { id, title } = this.props;
    const pageUrl = Foo();

    this.disqusConfig = {
      url: `${pageUrl}`,
      identifier: id,
      title: title,
    }
  
  }

  render() {
    return (
      <>
      {/* <CommentCount config={this.disqusConfig} placeholder={'...'} /> */}
      <Disqus config={this.disqusConfig} />
    </>
    );
  }
}

Comments.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Comments;

const Foo = () => {
  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <input type="text" readOnly="readonly" value={url} />
  );
};

