import React from "react";
import { graphql } from "gatsby";
import { Layout, Row, Col } from "antd";

import Header from "../../components/PageLayout/Header";
import Img from "gatsby-image";
import SidebarWrapper from "../../components/PageLayout/Sidebar";
import SEO from "../../components/seo";
import { useTranslation } from "react-i18next";
import Comment from '../../components/Comment';
import PostCard from "../../components/PostCard";
import style from "./post.module.less";

import getYouTubeId from "get-youtube-id";
import YouTube from "react-youtube";
import ImgBlock from "../../components/ImageBlock";
const BlockContent = require("@sanity/block-content-to-react");

const opts = {
  height: "250px",
  width: "100%"
};
const serializers = {
  types: {
    youtube: ({ node }) => {
      const { url } = node;
      const id = getYouTubeId(url);
      return (
        <Row type="flex" justify="center">
          <Col xs={24} sm={24} md={24} lg={8} xl={12}>
            <YouTube videoId={id} opts={opts} />
          </Col>
        </Row>
      );
    },
    image: ({ node }) => {
      return <ImgBlock node={node} />;
    }
  }
};

const Post = ({ data }) => {
  
  const keywords = data.sanityPost.ceo_tags.map(item => item.translate);

  const { i18n, t } = useTranslation("common");

  const MenuItems = data.allSanityMenu.nodes;

  return (
    <Layout className="outerPadding">
      <Layout className="container">
        <SEO
          lang={i18n.language}
          title={
            data.sanityPost.ceo_title != null
              ? data.sanityPost.ceo_title.translate
              : data.sanityPost.title.translate
          }
          description={
            data.sanityPost.ceo_description != null
              ? data.sanityPost.ceo_description.translate
              : data.sanityPost.title.translate
          }
          path={`blog/${data.sanityPost.slug.current}`}
          imageUrl={data.sanityPost.mainImage.asset.fluid.src}
          keywords={keywords}
        />

        <Header MenuItems={MenuItems} />
        <SidebarWrapper>
          <div className="marginTopTitle">
            <h1>{data.sanityPost.title.translate}</h1>
            <div className={style.bannerImgContainer}>
              <Img
                className={style.bannerImg}
                fluid={data.sanityPost.mainImage.asset.fluid}
                title={data.sanityPost.title.translate}
                alt={data.sanityPost.title.translate}
              />
            </div>

            <BlockContent
              blocks={data.sanityPost.body.translate}
              serializers={serializers}
            />
            <br/>
            <br/>
            <h2>{t('post_new_post')}</h2>
            <Row gutter={[20, 20]}>
              {data.allSanityPost.edges.map((val, key) => (
                <Col key={key} xs={24} sm={24} md={12} lg={8}>
                  <PostCard data={val} />
                </Col>
              ))}
            </Row>
            <br/>
            <br/>
            <h1>{t('discuss')}</h1>
            <Comment path={`/blog/${data.sanityPost.slug.current}/${i18n.language}`} title={t('discuss')} titlePage={data.sanityPost.title.translate} id={data.sanityPost.slug} />
          </div>
        </SidebarWrapper>
      </Layout>
    </Layout>
  );
};

export const query = graphql`
  query Post($language: String, $postId: String) {
    allSanityMenu(filter: { disabled: { in: true } }, sort: { fields: indx }) {
      nodes {
        title {
          translate(language: $language)
        }
        path
        externUrl
      }
    }
    sanityPost(id: { eq: $postId }) {
      title {
        translate(language: $language)
      }
      date_create(formatString: "Do MMMM, YYYY", locale: $language)
      mainImage {
        asset {
          fluid(maxWidth: 700) {
            ...GatsbySanityImageFluid
          }
        }
      }
      slug {
        current
      }
      body {
        translate(language: $language)
      }
      categories {
        title {
          translate(language: $language)
        }
      }
      ceo_description {
        translate(language: $language)
      }
      ceo_tags {
        translate(language: $language)
      }
      ceo_title {
        translate(language: $language)
      }
    }

    allSanityPost(
      sort: { order: DESC, fields: date_create }
      limit: 3
      filter: { id: {ne: $postId}}

    ) {
      edges {
        node {
          title {
            translate(language: $language)
          }
          date_create(formatString: "Do MMMM, YYYY", locale: $language)
          mainImage {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
          slug {
            current
          }
          categories {
            title {
              translate(language: $language)
            }
          }
        }
      }
    }
  }
`;

export default Post;
