// Компонент для отображения изображений в теле статьи
import React from "react";

// Gatsby provided Image tag
import Img from "gatsby-image";

// To convert Sanity image source to Gatsby Fluid image
import { getFluidGatsbyImage } from "gatsby-source-sanity";

// Sanity project config
const sanityConfig = { projectId: "l06qlm38", dataset: "production" };

const ImageBlock = ({ node }) => {
  const { asset } = node;

  // Convert to a Fluid Gatsby image
  const fluidProps = getFluidGatsbyImage(
    asset._ref,
    { maxWidth: 1024, sizes: "(max-width: 560px) 100vw, 560px" },
    sanityConfig
  );

  // Render the image
  return (
    <div className="block-image">
      <Img
        fluid={fluidProps}
        style={{ margin: "1rem", maxHeight: "calc(50vh - 4rem)" }}
        imgStyle={{ objectFit: "contain" }}
      />
    </div>
  );
};

export default ImageBlock;
